
import {
  Button,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
} from 'element-ui';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    size: {
      required: true,
      type: String,
    },
    shop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactForm: {
        civility: null,
        firstName: this.$auth.loggedIn
          ? this.$auth.user.Customer.Firstname
          : '',
        lastName: this.$auth.loggedIn ? this.$auth.user.Customer.Lastname : '',
        email: this.$auth.loggedIn ? this.$auth.user.Customer.Email : '',
        phone: '',
        cgv: [],
        newsletter: [],
      },
      contactFormRules: {
        civility: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'change',
          },
        ],
        firstName: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 3,
            message: this.$t('Length should more than {0}', [3]),
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 3,
            message: this.$t('Length should more than {0}', [3]),
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('Please input correct email address'),
            trigger: 'blur',
          },
        ],
        phone: [
          // {
          //   required: true,
          //   message: this.$t('Required Field'),
          //   trigger: "blur",
          // },
        ],
        cgv: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    civilityHandler() {},
    submitForm() {
      this.$refs['contactForm'].validate((valid) => {
        if (valid) {
          this.$emit('onSupmit', this);
        } else {
          return false;
        }
      });
    },
    change(step) {
      this.$emit('change', {
        step,
      });
    },
  },
  mounted() {},
  components: {
    Button,
    Form,
    FormItem,
    Input,
    Checkbox,
    CheckboxGroup,
    RadioGroup,
    Radio,
  },
};
