
import { ChevronRightIcon } from 'vue-feather-icons';
import Teleport from 'vue2-teleport';
import { Dialog } from 'element-ui';
export default {
  props: {
    lookbookProduct: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nbrCol: 6,
      nbrRow: 6,
      hovered: false,
      isVisible: false,
    };
  },
  computed: {
    // link() {
    //   if (this.product.Title) {
    //     return this.localePath(
    //       `/product/${this.product.Title.replace("/", "-").replace("#", "-")}-${this.lookbookProduct.product_url
    //       }`
    //     );
    //   }
    //   return this.localePath("/");
    // },
    hasPromotion() {
      if (this.product?.Price?.PromotionalPrice) {
        return this.product?.Price?.PromotionalPrice;
      }
      return false;
    },
    regularPrice() {
      return this.product?.Price?.RegularPrice?.PriceTaxIncl;
    },
    promotionPrice() {
      return this.product?.Price?.PromotionalPrice?.PriceTaxIncl;
    },
    promotionValue() {
      return this.product?.Price?.PromotionalPrice?.PriceRuleName;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    urlCategory() {
      try {
        const seo = this.product?.Category?.Main?.SEO;
        if (seo) {
          const mainCategory = seo?.find((item) => {
            return (
              this.$i18n.locale.toLocaleLowerCase() ===
              item.LanguageIsoCode.toLocaleLowerCase()
            );
          });
          if (!mainCategory) {
            return 'product';
          }
          if (mainCategory.URL) {
            return mainCategory.URL;
          }
        }
      } catch (error) {
        console.error(error);
      }
      return 'product';
    },

    urlProduct() {
      // try {
      //   const seo = this.product.SEO;
      //   if (seo) {
      //     const productSeo = seo?.find((item) => {
      //       return (
      //         this.$i18n.locale.toLocaleLowerCase() ===
      //         item.LanguageIsoCode.toLocaleLowerCase()
      //       );
      //     });
      //     if (!productSeo) {
      //       return this.product.IdProduct;
      //     }
      //     if (productSeo.ProductDescriptionFriendlyURL) {
      //       return productSeo.ProductDescriptionFriendlyURL;
      //     }
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
      return this.product?.SEO?.URL;
    },

    link() {
      try {
        if (this.product.SEO) {
          return this.localePath(`/${this.urlCategory}/${this.urlProduct}`);
        }
      } catch (error) {}

      return '#';
    },
  },
  methods: {
    position(lookbookProduct) {
      const row = Number(lookbookProduct.row.split('_')[1]);
      const col = Number(lookbookProduct.column.split('_')[1]);

      const top = (row / this.nbrRow) * 100;
      const left = (col / this.nbrCol - 1 / (this.nbrCol * 2)) * 100;

      return {
        top: top + '%',
        left: left + '%',
      };
    },
    display(lookbookProduct) {
      const col = Number(lookbookProduct.column.split('_')[1]);

      if (col >= this.nbrCol - 1) {
        return 'right';
      }
      return 'left';
    },
    showLink(val) {
      this.hovered = val;
    },
    loadProduct(product) {
      console.log('lookbook', product);

      // const productService = new ProductService();

      // const param = lookbook.url;
      // if (param) {
      //   const productId = param.split('-').pop();
      //   if (!product.value && productId) {
      //     productService
      //       .fetch(Number(productId))
      //       .then((data) => {
      //         product.value = data;
      //       })
      //       .catch((error) => {
      //         console.log('error', error);
      //       })
      //       .finally(() => {});
      //   }
      // }
    },
    openDrawer(lookbook) {
      if (process.client) {
        if (window.innerWidth < 992) {
          this.isVisible = true;
        }
      }
    },

    hoverProduct(lookbook) {
      console.log(lookbook);

      // if (windowWidth.value >= 992) {
      //   loadProduct(lookbook);
      // }
    },
    getCategoryLink(product) {
      if (product.Category) {
        const seoCat = product.Category.Main.SEO;
        try {
          let seo = seoCat[0];
          if (this.$i18n.locale === 'en') {
            seo = seoCat[1];
          }
          if (this.$i18n.locale === 'de') {
            seo = seoCat[2];
          }
          return seo.URL;
        } catch (error) {}
      }

      return 'product';
    },
  },
  components: { ChevronRightIcon, Dialog, Teleport },
};
