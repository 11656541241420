
import {
  Form,
  Input,
  Button,
  DatePicker,
  FormItem,
  Select,
  Option,
} from 'element-ui';
import { LocalisationHelper } from '@/helpers/LocalisationHelper';
import CustomerService from '../../../services/CustomerService';
export default {
  layout: 'account',
  name: 'MyAccount',
  data() {
    const localisationHelper = new LocalisationHelper(
      this.$http,
      this.$store,
      this.$auth
    );
    const userSession = {};
    const customer = this.$auth.user.Customer;
    userSession.firstName = customer.Firstname;
    userSession.lastName = customer.Lastname;
    userSession.email = customer.Email;
    userSession.birthDate = customer.Birthday;
    // userSession.language = customer.birthDate;
    if (customer.CurrencyIsoCode) {
      userSession.currencyIsoCode = customer.CurrencyIsoCode;
    }
    if (customer.LanguageIsoCode) {
      userSession.languageIsoCode = customer.LanguageIsoCode;
    }

    return {
      customerService: new CustomerService({
        auth: this.$auth,
        http: this.$http,
        store: this.$store,
      }),
      localisationHelper,
      userSession,
      newUser: null,
      rules: {
        firstName: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 3,
            message: 'Length should be 3 to 5',
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 3,
            message: 'Length should be 3 to 5',
            trigger: 'blur',
          },
        ],
      },
      currencies: [],
    };
  },
  computed: {
    languages() {
      return this.$i18n.localeCodes;
    },
  },
  async mounted() {
    // this.userSession = this.$auth.$storage.getCookie("USER_CONNECTED").customer;
    this.getCurrencies();
  },
  updated() {
    if (process.client) {
      document.body.classList.add('account-index-page');
    }
  },
  unmounted() {
    if (process.client) {
      document.body.classList.remove('account-index-page');
    }
  },
  methods: {
    saveCustomer() {
      // var qs = require("qs");
      // var data = qs.stringify({
      //   Firstname: this.userSession.firstName,
      //   Lastname: this.userSession.lastName,
      //   Birthday: this.userSession.birthDate,
      //   LanguageIsoCode: this.userSession.languageIsoCode,
      //   CurrencyIsoCode: this.userSession.currencyIsoCode,
      // });

      this.customerService
        .edit({ customer: this.userSession })
        .then((res) => {
          this.$router.push(this.localePath('/account/informations'));
        })
        .catch((error) => {})
        .finally(() => {
          //Perform action in always
        });
    },
    async getCurrencies() {
      const list = await this.localisationHelper.getAllCurrencies();
      if (list) {
        this.currencies = list;
      }
    },
    onSubmit() {
      this.$refs['editInfoForm'].validate((valid) => {
        if (valid) {
          this.saveCustomer();
        } else {
          return false;
        }
      });
    },
  },
  components: {
    Input,
    Button,
    Form,
    DatePicker,
    FormItem,
    Select,
    Option,
  },
};
