
import { LookBooksPageQuery } from '~/graphql/queries/LookBooks';
export default {
  layout: 'MainLayout',
  name: 'LookBookPage',
  auth: false,

  data() {
    return {
      lookBooks: null,
    };
  },

  methods: {
    getFullImgCardImageUrl(val) {
      return this.$GLOBAL_VARIABLES.cmsUrl + val.image.data.attributes.url;
    },
  },

  apollo: {
    lookBooks: {
      query: LookBooksPageQuery,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },
};
