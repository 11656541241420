
export default {
  props: {
    variants: {
      type: Array,
    },
  },
  data() {
    return {
      idProductAttributeSelected: null,
    };
  },
  methods: {
    selectSize(variant) {
      this.idProductAttributeSelected =
        this.getCombination(variant).IdProductAttribute;
      this.$emit("onSelect", {
        variant,
        size: this.getSize(this.getCombination(variant))?.AttributeName,
        color: this.getColor(this.getCombination(variant))?.AttributeName,
      });
    },
    getCombination(variant) {
      try {
        const size = variant.Combination;
        return size;
      } catch (error) {}
      return null;
    },
    getSize(combination) {
      try {
        const size = combination.Attributes.find(
          (attribute) => attribute.GroupName === "Size"
        );
        return size;
      } catch (error) {}
      return {};
    },
    getColor(combination) {
      try {
        const size = combination.Attributes.find(
          (attribute) => attribute.GroupName === "Color"
        );
        return size;
      } catch (error) {}
      return {};
    },
  },
};
