
import {
  UserIcon,
  ShoppingBagIcon,
  HeartIcon,
  CornerDownRightIcon,
  CornerUpLeftIcon,
  AwardIcon,
  MessageSquareIcon,
  LogOutIcon,
  UsersIcon,
} from 'vue-feather-icons';
import AccountBody from '@/pages/account/components/AccountBody.vue';
import LoyaltyProgress from '@/pages/account/components/LoyaltyProgress.vue';
import { Button, Progress } from 'element-ui';
import NewsLetterCheckbox from '@/components/NewsLetterCheckbox.vue';
import accountMixin from '@/mixins/accountMixin';

export default {
  layout: 'account',
  name: 'profilHomePage',
  middleware: 'auth',
  mixins: [accountMixin],

  computed: {
    customer() {
      return this.$store.state.shop.loyalty.customer;
    },
    lasteOrder() {
      if (this.ordersData?.Orders?.length) {
        return this.ordersData.Orders[0];
      }
      return null;
    },

    circleOptions() {
      return {
        width: 140,
        strokeWidth: 16,
      };
    },
  },
  mounted() {
    this.$store.dispatch('shop/loyalty/fetchLoyaltyCustomer', {
      onSuccess: () => {},
    });
    if (!this.ordersData?.Orders) {
      this.loading = true;
      this.accountService.fetchOrders({
        onSuccess: () => {
          this.loading = false;
        },
        onError: () => {
          this.loading = false;
        },
      });
    }
  },

  components: {
    AccountBody,
    LoyaltyProgress,
    Button,
    NewsLetterCheckbox,
    Progress,
    UserIcon,
    ShoppingBagIcon,
    HeartIcon,
    CornerDownRightIcon,
    CornerUpLeftIcon,
    AwardIcon,
    MessageSquareIcon,
    LogOutIcon,
    UsersIcon,
  },
};
