export class TunnelHelper {
  constructor(http, auth, store = null, lang = 'en') {
    // this.context = context;
    this.http = http;
    this.auth = auth;
    this.store = store;
    if (lang === 'fr') {
      this.lang = 1;
    } else if (lang === 'de') {
      this.lang = 3;
    } else {
      this.lang = 2;
    }
  }

  async getListCarriers(idAddress) {
    try {
      var FormData = require('form-data');
      var formData = new FormData();
      formData.append('IdAddress', idAddress);
      formData.append('IdLang', this.lang);
      const res = await this.http.API_AUTH.$post('carriersByAddress', formData);
      if (Array.isArray(res)) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // {
  //   $auth: this.auth,
  //   cartTotal: cart.CartTotal,
  // }

  async getRelayPointsByCityAndPostcode({
    city,
    postalCode,
    country,
    address,
    idCarrier,
    carrierType,
  }) {
    try {
      const res = await this.http.API_AUTH.$get(
        `carrier/relayPoint?Postcode=${postalCode}&City=${city}&Address1=${address}&Country=${country}&IdCarrier=${idCarrier}&CarrierType=${carrierType}`
      );
      return res;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getRelayPointsByIdAddressAndIdCarrier({
    idAddress,
    idCarrier,
    carrierType = 'RelayPoint',
  }) {
    try {
      const res = await this.http.API_AUTH.$get(
        `carrier/relayPoint?IdAddress=${idAddress}&IdCarrier=${idCarrier}&CarrierType=${carrierType}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  // save Cart

  async saveCart(customData) {
    const idCart = this.store.state.shop.cart.idCart;
    try {
      var FormData = require('form-data');
      var formData = new FormData();
      formData.append('IdCart', idCart);
      formData.append('CustomData', customData);
      const res = await this.http.API_AUTH.$post(
        'customer/cart/saveCart',
        formData
      );
      if (res.status === 'success') {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // Adresse de livraison
  setCurrentAddress(address) {
    this.store.commit('shop/tunnel/address/setCurrentAddress', {
      address,
      $auth: this.auth,
    });
  }
  getCurrentAddress() {
    return this.store.state.shop.tunnel.address.currentAddress;
  }

  // Adresse de facturaton
  setCurrentInvoiceAddress(address) {
    this.store.commit('shop/tunnel/address/setCurrentInvoiceAddress', {
      address,
      $auth: this.auth,
    });
  }
  getCurrentInvoiceAddress() {
    return this.store.state.shop.tunnel.address.currentInvoiceAddress;
  }

  // Option de livraison /Carrier
  setDeliveryOption(deliveryOption) {
    this.store.commit('shop/tunnel/address/setDeliveryOption', {
      deliveryOption,
      $auth: this.auth,
    });
  }
  getDeliveryOption() {
    return this.store.state.shop.tunnel.address.deliveryOption;
  }

  // Point de ralais
  setRelayPoint(relayPoint) {
    this.store.commit('shop/tunnel/address/setRelayPoint', {
      relayPoint,
      $auth: this.auth,
    });
  }
  getRelayPoint() {
    return this.store.state.shop.tunnel.address.relayPoint;
  }

  init() {
    this.store.commit('shop/tunnel/address/initialiseStore', this.auth);
  }

  clear() {
    this.store.commit('shop/tunnel/address/clearStore', this.auth);
  }
}
