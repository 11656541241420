import { render, staticRenderFns } from "./BookingDialogStepShopContact.vue?vue&type=template&id=90a126dc"
import script from "./BookingDialogStepShopContact.vue?vue&type=script&lang=js"
export * from "./BookingDialogStepShopContact.vue?vue&type=script&lang=js"
import style0 from "./BookingDialogStepShopContact.vue?vue&type=style&index=0&id=90a126dc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmallCardProduct: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/SmallCardProduct.vue').default,CardAddress: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/CardAddress.vue').default})
