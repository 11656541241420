
import Banner from '@/components/Banner.vue';
import { Collapse, CollapseItem } from 'element-ui';
import {
  UserIcon,
  ShoppingBagIcon,
  HeartIcon,
  CornerDownRightIcon,
  CornerUpLeftIcon,
  ChevronLeftIcon,
  MenuIcon,
} from 'vue-feather-icons';
import AccountSideBar from '@/pages/account/components/AccountSideBar.vue';
import Address from '@/pages/account/components/Address.vue';
import AccountInfo from '@/pages/account/components/AccountInfo.vue';
export default {
  layout: 'account',
  name: 'accountBody',
  data() {
    return {
      userSession: this.$auth.user.Customer,
      tab: '',
      sidebarOpen: '',
    };
  },
  components: {
    Banner,
    Collapse,
    CollapseItem,
    UserIcon,
    ShoppingBagIcon,
    HeartIcon,
    CornerDownRightIcon,
    CornerUpLeftIcon,
    ChevronLeftIcon,
    AccountSideBar,
    Address,
    AccountInfo,
    MenuIcon,
  },
  methods: {
    initUser() {
      this.userSession = this.$auth.user.Customer;
    },
  },
  activated() {
    this.initUser();
  },

  mounted() {
    this.tab = this.$route.name.includes('account-informations')
      ? 'informations'
      : '';
    this.initUser();
  },
};
