
import {
  Form,
  Input,
  Checkbox,
  Button,
  FormItem,
  Select,
  Option,
  CollapseTransition,
} from 'element-ui';
import { ChevronDownIcon } from 'vue-feather-icons';
import { AddressHelpers } from '@/helpers/AddressHelpers';
import addressMixin from '@/mixins/addressMixin';

export default {
  props: {
    idAddress: {
      type: String | Number,
      default: null,
    },
  },
  mixins: [addressMixin],
  data() {
    this.setCustomerConected();
    return {
      customer: null,
      // addressInfo: {
      //   Alias: "Addree111",
      //   Lastname: "alexander",
      //   Firstname: "toress",
      //   Address1: "addresss heloo wolrd",
      //   Address2: "addrrr",
      //   Postcode: "110000",
      //   City: "Paris",
      //   Country: "France",
      //   MobilePhone: "0566996699",
      //   Phone: "0668559955",
      // },
      openAdd: false,
      showAddressLine2: false,
      addressInfo: {
        Alias: '',
        Lastname: this.customer?.Lastname,
        Firstname: this.customer?.Firstname,
        Address1: '',
        Address2: '',
        Postcode: '',
        City: '',
        CountryIsoCode: '',
        MobilePhone: '',
        Phone: '',
        IsInvoice: true,
        IsDelivery: true,
      },
      rules: {
        Lastname: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        Firstname: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        Address1: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        Address2: [
          // {
          //   required: true,
          //   message: this.$t('Required Field'),
          //   trigger: "blur",
          // },
        ],
        Postcode: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        City: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        CountryIsoCode: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: ['blur', 'change'],
          },
        ],
        MobilePhone: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        // Phone: [
        //   {
        //     required: true,
        //     message: this.$t('Required Field'),
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  computed: {
    countries() {
      return this.$localisation.countries;
    },
  },
  async mounted() {
    // if (this.address != null) {
    //   this.addressInfo = this.address;
    // }

    if (process.client) {
      if (this.$route.hash === '#mobilephone') {
        const field = this.$refs?.mobilePhone.$el.querySelector('input');

        field.focus();

        this.scrollTo(field);
      }
    }

    if (this.idAddress) {
      const listAddress = this.$store.state.user.address.listAddress;

      if (listAddress.length === 0) {
        await this.getAddresses();
      }
      const idAddress = this.idAddress;
      const address = this.$store.state.user.address.listAddress.filter(
        (item) => {
          return item.IdAddress === Number(idAddress);
        }
      )[0];

      this.addressInfo = { ...address };
    }
    setTimeout(() => {
      this.openAdd = true;
    }, 100);
  },
  methods: {
    // addresAutoCompleteTextChange($event) {
    //   this.addressInfo.Address1 = $event.text;
    // },

    scrollTo(element) {
      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    },
    setCustomerConected() {
      let userGuest = this.$store.state.user.guest.guestUser;

      if (this.$auth.loggedIn) {
        this.customer = this.$auth.user.Customer;
      } else if (userGuest) {
        this.customer = userGuest;
      }
    },
    getListAddresses() {
      const list = this.$store.state.user.address.listAddress;
      return list;
    },
    async submitForm() {
      this.$refs['AddressForm'].validate(async (valid) => {
        if (valid) {
          if (this.idAddress) {
            await this.updateAddress(this.idAddress);
          } else {
            await this.createAddress();
          }
        } else {
          return false;
        }
      });
    },
    async createAddress() {
      this.addressService
        .add(this.addressInfo)
        .then((data) => {
          this.addressService.fetchAddresses({
            onSuccess: () => {
              this.$emit('onSubmit', data);
            },
          });
        })
        .catch((err) => {});
    },

    updateAddress(idAddress) {
      this.addressService
        .update(idAddress, this.addressInfo)
        .then((data) => {
          this.addressService.fetchAddresses({
            onSuccess: () => {
              this.$emit('onSubmit', data);
            },
          });
        })
        .catch((err) => {});
    },

    onSelectAddress($event) {
      // const words = $event.formatted_address.split(",");
      // this.addressInfo.Address1 = "";
      // if (words.length) {
      // }

      this.addressInfo.Address1 = $event.courtAddress;

      this.getCountrySlected($event.country);

      this.addressInfo.Country = $event.country ? $event.country : '';
      this.addressInfo.City = $event.city ? $event.city : '';
      this.addressInfo.Postcode = $event.postalCode ? $event.postalCode : '';

      this.$refs['AddressForm'].validate();
    },

    onAddressTextChange(e) {
      this.addressInfo.Address1 = e.text;
    },

    getCountrySlected(country) {
      if (country) {
        const country_selected = this.countries.find(
          (item) => item.CountryName?.toLowerCase() === country?.toLowerCase()
        );
        if (country_selected) {
          this.addressInfo.CountryIsoCode = country_selected.CountryIsoCode;
        } else {
          this.addressInfo.CountryIsoCode = '';
        }
      } else {
        this.addressInfo.CountryIsoCode = '';
      }
    },

    async getAddresses() {
      const addressHelpers = new AddressHelpers(this.$http, this.$store);
      await addressHelpers.getList();
    },
  },
  components: {
    Form,
    Input,
    Checkbox,
    Button,
    FormItem,
    Select,
    Option,
    CollapseTransition,
    ChevronDownIcon,
  },
};
