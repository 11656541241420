
import { Input, Button, Form, FormItem } from 'element-ui';
import FacebookButton from '@/components/FacebookButton.vue';
import GoogleView from '@/components/GoogleView.vue';
import SocialLoginBtns from '@/pages/authenticate/components/SocialLoginBtns.vue';
export default {
  data() {
    return {
      login: {
        user: '',
        pass: '',
      },
      rules: {
        user: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('Please input correct email address'),
            trigger: 'blur',
          },
        ],
      },
      isLogin: false,
    };
  },
  components: {
    Input,
    Button,
    Form,
    FormItem,
    FacebookButton,
    GoogleView,
    SocialLoginBtns,
  },
  methods: {
    open1() {},
    registerClick() {
      this.$refs['registerForm0'].validate((valid) => {
        if (valid) {
          this.$emit('onRegisterClick', {
            email: this.login.user,
            route: this.$route,
          });
        } else {
          return false;
        }
      });
    },
    async glogin() {
      const googleUser = await this.$gAuth.signIn();
    },
  },
};
